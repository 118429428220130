import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import GoogleAutocomplete from "react-google-autocomplete";
import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Progress,
  Spinner,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { confirmAlert } from "react-confirm-alert"; // Import

import Loader from "../../Components/Common/Loader";
import { add_cookie, get_cookie } from "../../helpers/get_cookie";

import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import setCanvasPreview from "../../setCanvasPreview";

const MIN_DIMENSION = 100;

const WorkForceSetup = () => {
  // image cropper
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [croppedImgSrc, setCroppedImgSrc] = useState(null);

  const navigate = useNavigate();

  const [authUserId, setAuthUserId] = useState();
  const [activeTab, setactiveTab] = useState(1);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [registrationProgress, setRegistrationProgress] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);

  //tab - preferences
  const [availableStartDate, setAvailableStartDate] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [allIndustryNames, setAllIndustryNames] = useState([]);
  const [employeeChecked, setEmployeeChecked] = useState(false);
  const [contractorChecked, setContractorChecked] = useState(false);
  const [volunteerChecked, setVolunteerChecked] = useState(false);
  const [hourlyRate, setHourlyRate] = useState("");
  const [monthlySalary, setMonthlySalary] = useState("");
  const [currentAvailability, setCurrentAvailability] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  //tab-preferred availability

  const [preferredAvailability, setPreferredAvailability] = useState();
  const [mondayFromTime, setMondayFromTime] = useState("unavailable");
  const [mondayToTime, setMondayToTime] = useState();
  const [tuesdayFromTime, setTuesdayFromTime] = useState("unavailable");
  const [tuesdayToTime, setTuesdayToTime] = useState();
  const [wednesdayFromTime, setWednesdayFromTime] = useState("unavailable");
  const [wednesdayToTime, setWednesdayToTime] = useState();
  const [thursdayFromTime, setThursdayFromTime] = useState("unavailable");
  const [thursdayToTime, setThursdayToTime] = useState();
  const [fridayFromTime, setFridayFromTime] = useState("unavailable");
  const [fridayToTime, setFridayToTime] = useState();
  const [saturdayFromTime, setSaturdayFromTime] = useState("unavailable");
  const [saturdayToTime, setSaturdayToTime] = useState();
  const [sundayFromTime, setSundayFromTime] = useState("unavailable");
  const [sundayToTime, setSundayToTime] = useState();
  const [showToast, setShowToast] = useState(false);

  const [isMondayAvailable, setIsMondayAvailable] = useState(true);
  const [isTueAvailable, setIsTueAvailable] = useState(true);
  const [isWedAvailable, setIsWedAvailable] = useState(true);
  const [isThuAvailable, setIsThuAvailable] = useState(true);
  const [isFriAvailable, setIsFriAvailable] = useState(true);
  const [isSatAvailable, setIsSatAvailable] = useState(true);
  const [isSunAvailable, setIsSunAvailable] = useState(true);

  //tab - work locations
  const [userlocation, setUserlocation] = useState(null);
  const [editlatitude, seteditlatitude] = useState("");
  const [editlongitude, seteditlongitude] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [editsubalone, seteditsubalone] = useState();
  const [editpostcode, seteditpostcode] = useState();
  const [editstate, seteditstate] = useState([]);
  const [suburbAlone, setselectedSuburbAlone] = useState();
  const [selectedState, setselectedState] = useState([]);
  const [selectedLatitude, setselectedLatitude] = useState("");
  const [selectedLongitude, setselectedLongitude] = useState("");
  const [radius, setRadius] = useState();
  const [isLocation, setIsLocation] = useState(false);

  //tab - profile
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setContactNumber] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [skilldata, setSkillData] = useState([]);
  const [profile_title, setprofiletitle] = useState("");
  const [profilePic, setProfilePicture] = useState("");
  const [profileSummary, setProfileSummary] = useState("");
  const [summary, setSummary] = useState("");
  const [work_id, setworkid] = useState();
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");

  // const [selectedFiles, setselectedFiles] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCity, setSelectedcity] = useState();
  const [selectedSuburb, setselectedSuburb] = useState();
  const [selectedPostcode, setselectedPostcode] = useState();
  const [country, setCountry] = useState([]);
  const [suburb, setSuburb] = useState("");
  const [isProfileCompleted, setIsProfileCompleted] = useState(false);
  const [profileStepCount, setProfileStepCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const progressMap = {
    0: 0,
    1: 30,
    2: 70,
    3: 100,
  };

  let progressBarWidth = "0";
  if (registrationProgress === 30) {
    progressBarWidth = "33";
  } else if (registrationProgress === 70) {
    progressBarWidth = "66";
  } else if (registrationProgress === 100) {
    progressBarWidth = "100";
  }

  // Update canvas preview
  const updateCanvasPreview = (crop) => {
    if (
      imgRef.current &&
      previewCanvasRef.current &&
      crop &&
      crop.width &&
      crop.height
    ) {
      setCanvasPreview(
        imgRef.current, // HTMLImageElement
        previewCanvasRef.current, // HTMLCanvasElement
        convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
      );

      const canvas = previewCanvasRef.current;
      const dataUrl = canvas.toDataURL("image/jpeg");
      setCroppedImgSrc(dataUrl);
    }
  };

  // Effect to update canvas preview
  useEffect(() => {
    if (imgSrc && crop) {
      updateCanvasPreview(crop);
    }
  }, [imgSrc, crop]);

  function handleSkillsMulti(selectedLeadStatus) {
    setSelectedSkills(selectedLeadStatus);
    // console.log(selectedLeadStatus);
  }
  function handleIndustryMulti(selectedLeadStatus) {
    setSelectedIndustry(selectedLeadStatus);
  }

  const handleRadiusChange = (e) => {
    // Ensure the input is a valid positive integer with a maximum of 3 digits
    const inputValue = e.target.value.replace(/\D/g, "").substring(0, 3);
    setRadius(inputValue);
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        const hour = h < 10 ? `0${h}` : `${h}`;
        const minute = m === 0 ? "00" : `${m}`;
        const time = `${hour}:${minute}`;
        options.push({ label: formatTime(time), value: time });
      }
    }
    options.unshift({ label: "Available anytime", value: "available_anytime" });
    options.unshift({ label: "Unavailable", value: "unavailable" });
    return options;
  };
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const formattedHour = parseInt(hour, 10) % 12 || 12;
    const period = parseInt(hour, 10) < 12 ? "AM" : "PM";
    return `${formattedHour}:${minute} ${period}`;
  };

  useEffect(() => {
    if (!isMondayAvailable) {
      setMondayFromTime("unavailable");
    }
    if (!isTueAvailable) {
      setTuesdayFromTime("unavailable");
    }
    if (!isWedAvailable) {
      setWednesdayFromTime("unavailable");
    }
    if (!isThuAvailable) {
      setThursdayFromTime("unavailable");
    }
    if (!isFriAvailable) {
      setFridayFromTime("unavailable");
    }
    if (!isSatAvailable) {
      setSaturdayFromTime("unavailable");
    }
    if (!isSunAvailable) {
      setSundayFromTime("unavailable");
    }
  }, []);

  const timeOptions = generateTimeOptions();
  useEffect(() => {
    // Handle initial state based on the value from the backend
    if (mondayFromTime === "unavailable") {
      setMondayToTime("default");
      setIsMondayAvailable(false);
    } else {
      setIsMondayAvailable(true);
    }
    if (tuesdayFromTime === "unavailable") {
      setTuesdayToTime("default");
      setIsTueAvailable(false);
    } else {
      setIsTueAvailable(true);
    }
    if (wednesdayFromTime === "unavailable") {
      setWednesdayToTime("default");
      setIsWedAvailable(false);
    } else {
      setIsWedAvailable(true);
    }
    if (thursdayFromTime === "unavailable") {
      setThursdayToTime("default");
      setIsThuAvailable(false);
    } else {
      setIsThuAvailable(true);
    }
    if (fridayFromTime === "unavailable") {
      setFridayToTime("default");
      setIsFriAvailable(false);
    } else {
      setIsFriAvailable(true);
    }
    if (saturdayFromTime === "unavailable") {
      setSaturdayToTime("default");
      setIsSatAvailable(false);
    } else {
      setIsSatAvailable(true);
    }
    if (sundayFromTime === "unavailable") {
      setSundayToTime("default");
      setIsSunAvailable(false);
    } else {
      setIsSunAvailable(true);
    }
  }, [
    mondayFromTime,
    tuesdayFromTime,
    wednesdayFromTime,
    thursdayFromTime,
    fridayFromTime,
    saturdayFromTime,
    sundayFromTime,
  ]);
  useEffect(() => {
    processAvailabilityData();
  }, [preferredAvailability]);

  useEffect(() => {
    const userId = JSON.parse(get_cookie("workauthUser")).user_id;
    console.log(userId, "auth user");
    fetchUserData(userId);
    setAuthUserId(userId);

    axios
      .get("/list-skills")
      .then((res) => {
        console.log(res);
        if (res.data && res.message === "Success") {
          // Assuming the skills are nested under 'data'
          const skillsData = res.data;

          // Update state with the fetched skills
          setSkills(skillsData);

          // Log the skills to the console for debugging
          //
        } else {
          // Handle case where skills are not found
          console.error("Skills not found");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getIndustries();
  }, []);

  const getIndustries = () => {
    axios
      .get("/list-industries")
      .then((res) => {
        if (res.message == "Success") {
          // toast.success(res.message, { theme: "light" });
          setAllIndustryNames(res.industry_names);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const editlocationfield = (place, item) => {
    const addressComponents = place?.address_components;

    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );

    let selectedSuburb = "";
    let selectedState = "";
    let selectedPostcode = "";
    let selectedLatitude = "";
    let selectedLongitude = "";

    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
      if (stateComponent) {
        selectedState = stateComponent.short_name;
      }
    }

    let suburbAlone = suburbComponent?.long_name || "";

    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }

    if (place.geometry && place.geometry.location) {
      selectedLatitude = place.geometry.location.lat();
      selectedLongitude = place.geometry.location.lng();
    }

    if (selectedState) {
      selectedSuburb += `, ${selectedState}`;
    }
    seteditsubalone(suburbAlone); // Assuming you want to store the suburb only
    seteditpostcode(selectedPostcode);
    seteditstate(selectedState);
    seteditlatitude(selectedLatitude);
    seteditlongitude(selectedLongitude);

    // Update the userlocation array with the edited values
    setUserlocation((prevUserLocation) => {
      const updatedData = prevUserLocation.map((task) =>
        task.id === item.id ? { ...task, location: selectedSuburb } : task
      );
      return updatedData;
    });
  };
  const handleeditradiuschange = (item) => (e) => {
    const enteredValue = e.target.value;

    // Ensure the input is a valid positive integer with a maximum of 3 digits
    const sanitizedValue = enteredValue.replace(/\D/g, "").substring(0, 3);

    const updatedData = userlocation.map((task) =>
      task.id === item.id ? { ...task, radius_distance: sanitizedValue } : task
    );

    setUserlocation(updatedData);
  };

  const t_location = (id, cancel = false) => {
    const updateddata = [...userlocation];
    const taskIndex = updateddata.findIndex((task) => task.id === id);
    //
    updateddata[taskIndex].isEditing =
      updateddata[taskIndex].isEditing == null
        ? true
        : !updateddata[taskIndex].isEditing;
    console.log(updateddata[taskIndex], "updateddata");
    setUserlocation(updateddata);
    seteditsubalone(updateddata[taskIndex].suburb);
    seteditlatitude(updateddata[taskIndex].latitude);
    seteditlongitude(updateddata[taskIndex].longitude);
    seteditpostcode(updateddata[taskIndex].post_code);
    seteditstate(updateddata[taskIndex].state);

    if (cancel) {
      fetchUserData(authUserId);
    }
    //setShowEducation(!showEducation);
  };
  const deletelocation = (item) => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this location?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              id: item.id,
            };

            axios
              .post("/delete-work-location", data)
              .then((res) => {
                if (res.message === "Success") {
                  toast.success("Location Deleted Successfully", {
                    theme: "light",
                  });
                }
                fetchUserData(authUserId);
              })
              .catch((err) => {
                console.error(err);
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const selectSuburbOption = (place) => {
    // Check if geometry is present
    if (!place.geometry || !place.geometry.location) {
      console.error("Invalid place data:", place);
      return;
    }

    const addressComponents = place?.address_components;

    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );

    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;

      // Concatenate state if available
      if (stateComponent) {
        selectedSuburb += `, ${stateComponent.short_name}`;
      }
    }

    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }

    // Extract suburb and state separately
    let suburbAlone = suburbComponent?.long_name || "";
    let selectedState = stateComponent?.short_name || "";
    let postcode = selectedPostcode || "";

    // Check if location has latitude and longitude information
    const locationGeometry = place?.geometry;
    const latitude = locationGeometry?.location.lat() || null;
    const longitude = locationGeometry?.location.lng() || null;

    // Update your state variables
    setselectedSuburb(selectedSuburb);
    setselectedSuburbAlone(suburbAlone);
    setselectedPostcode(postcode);
    setselectedState(selectedState);
    setselectedLatitude(latitude);
    setselectedLongitude(longitude);
  };

  const autocompleteService = useRef(null);
  const itemIdRef = useRef(null);

  const handleKeyUp = async (e, inputType, item = "") => {
    const input = e.target.value || inputValue;
    console.log("Input value:", input);
    // Set the itemIdRef to the current item.id
    itemIdRef.current = item.id;

    if (e.key === "Enter") {
      e.preventDefault();
      try {
        const predictions = await getPlacePredictions(input, inputType);

        if (predictions && predictions.length > 0) {
          const firstPlaceId = predictions[0].place_id;
          const request = {
            placeId: firstPlaceId,
            fields: [
              "address_components",
              "formatted_address",
              "geometry",
              "place_id",
            ],
          };

          const placesService = new window.google.maps.places.PlacesService(
            document.createElement("div")
          );
          placesService.getDetails(request, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              if (inputType === "location") {
                residentialplace(place);
              } else if (inputType === "suburb") {
                selectSuburbOption(place);
              } else if (inputType === "editsuburb") {
                // Access the itemIdRef.current to get the correct item.id
                editlocationfield(place, { id: itemIdRef.current, ...item });
              }
            } else {
              console.error("Error fetching place details:", status);
            }
          });
        }
      } catch (error) {
        console.error("Error fetching predictions:", error);
      }
    }
  };
  const getPlacePredictions = (input, inputType) => {
    return new Promise((resolve, reject) => {
      if (!autocompleteService.current) {
        autocompleteService.current =
          new window.google.maps.places.AutocompleteService();
      }

      const types = inputType === "location" ? ["geocode"] : ["(regions)"];

      autocompleteService.current.getPlacePredictions(
        {
          input,
          types,
          componentRestrictions: { country: "au" },
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve(predictions);
          } else {
            reject(status);
          }
        }
      );
    });
  };

  const submitlocation = () => {
    // Check if suburb or radius is empty
    if (!selectedSuburb) {
      toast.warning("Please enter the suburb.", { theme: "light" });
      return;
    }
    if (!radius || isNaN(radius) || parseFloat(radius) <= 0) {
      toast.warning("Please provide a valid radius.", { theme: "light" });
      return;
    }

    // Set isLoading to true to show the loader
    setIsLoading(true);

    let data = {
      country_code: "au",
      state: selectedState,
      country_id: 8,
      workforce_profile_id: authUserId,
      suburb: suburbAlone,
      postcode: selectedPostcode,
      radius_distance: radius,
      longitude: selectedLongitude,
      latitude: selectedLatitude,
    };

    axios
      .post("/add-work-location", data)
      .then((res) => {
        if (res.message === "Success") {
          toast.success("Location Added Successfully", { theme: "light" });
          // setstatelocation(res.state);
          // setressuburb(res.suburb);
          fetchUserData(authUserId);
          setRadius("");
          setInputValue("");
          setselectedSuburb("");
          setselectedSuburbAlone("");
          setselectedPostcode();
          setselectedState([]);
          setselectedLatitude("");
          setselectedLongitude("");
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        // Set isLoading to false when the request is complete (whether successful or not)
        setIsLoading(false);
      });
  };

  const savelocation = (item) => {
    // Check if suburb is empty
    if (!editsubalone) {
      toast.warning("Please enter the suburb.", { theme: "light" });
      return;
    }
    // Check if radius is empty or not a valid number
    if (
      !item.radius_distance ||
      isNaN(item.radius_distance) ||
      parseFloat(item.radius_distance) <= 0
    ) {
      toast.warning("Please provide a valid radius.", { theme: "light" });
      return;
    }

    setIsLoading(true); // Set isLoading to true to show the loader

    let data = {
      id: item.id,
      workforce_profile_id: item.workforce_profile_id,
      country_code: "au",
      state: editstate,
      suburb: editsubalone,
      postcode: editpostcode,
      latitude: editlatitude,
      longitude: editlongitude,
      radius_distance: item.radius_distance,
    };

    axios
      .post("/update-work-location", data)
      .then((res) => {
        if (res.error === 0) {
          toast.success("Location Updated Successfully", { theme: "light" });

          fetchUserData(authUserId);
        } else {
          toast.error(res.message, { theme: "light" });
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false); // Set isLoading to false to hide the loader
        t_location(item.id, true);
        //fetchUserProfile();
      });
  };

  const submitCompletion = async () => {
    if (isLocation) {
      setIsLoading(true);

      const formData = {
        user_id: authUserId,
        step: 5,
      };
      axios.post("/registration-step", formData).then((res) => {
        const authUser = JSON.parse(get_cookie("workauthUser"));
        authUser.profile_setup_completed = 1;
        add_cookie(authUser, false);
        setTimeout(() => {
          setIsLoading(false);

          navigate("/overview"); // Set initialized state after navigation container has mounted
        }, 500);
      });
    } else {
      toast.error(
        "Please add atleast one preferred work location"
      );
    }
  };

  const fetchUserData = async (userId) => {
    setIsLoading(false);
    console.log(userId, " user id console");
    if (userId !== null) {
      console.log("jennifererer");
      axios.get(`/user-details/${userId}`).then((response) => {
        console.log(response, "jen");
        if (response.message === "Success") {
          const userDetails = response.data.user_details;
          const userProfile = response.data.user_profile[0];
          const workforceProfileId = response.data.workforce_profile_id;
          const preferences = response.data.preferences;
          const workprofileid = response.data.workforce_profile_id;
          const preferredWorkLocations = response.data.locations;
          if (response.data.locations.length == 0) {
            setIsLocation(false);
          } else {
            setIsLocation(true);
          }
          if (userDetails.profile_setup_completed == 1) {
            navigate("/overview");
            return;
          } else {
            if (userDetails.profile_setup_step != 4) {
              setactiveTab(userDetails.profile_setup_step );
            } else {
              setactiveTab(userDetails.profile_setup_step);
            }
          }
          setworkid(workforceProfileId);
          setUserlocation(preferredWorkLocations);
          console.log(userDetails, "First name");
          if (userDetails) {
            const firstName = userDetails.first_name;
            const lastName = userDetails.last_name;
            setfirstname(firstName);
            setlastname(lastName);
            setProfileStepCount(userDetails.profile_setup_step);
            setIsProfileCompleted(userDetails.profile_setup_completed === 1);
          }

          if (userProfile) {
            const email = userProfile.email;
            const phone_number = userProfile.contact_number;
            const skills = userProfile.workforce_skills;
            const profile_pic = userProfile.profile_pic;
            const suburbb = userProfile.suburb;
            const addresss = userProfile.address;
            const country_namee = userProfile.country_name;
            const latitudee = userProfile.latitude;
            const longitudee = userProfile.longitude;

            setProfilePicture(profile_pic);

            setSuburb(suburbb);
            setAddress(addresss);
            setCountry(country_namee);
            setFullAddress(addresss);
            setEmail(email);
            setContactNumber(phone_number);
            const defaultSelectedSkillsOptions = skills?.map((skill) => ({
              value: skill.id,
              label: skill.name,
            }));
            setSelectedSkills(defaultSelectedSkillsOptions);
            setSkillData(skills);
            setLatitude(latitudee);
            setLongitude(longitudee);
            if (userProfile.workforce_availability.length > 0) {
              setCurrentAvailability(userProfile.workforce_availability[0]);
            }
            const defaultSelectedOptions = userProfile.workforce_industry?.map(
              (item) => ({
                value: item.id,
                label: item.name,
              })
            );
            setSelectedIndustry(defaultSelectedOptions);
          }

          if (preferences) {
            const profiletitle = preferences.profile_title;
            const profile_summary = preferences.profile_summary;
            setprofiletitle(profiletitle);
            setProfileSummary(profile_summary);
            setSummary(profile_summary);
            setEmployeeChecked(preferences.is_employee == 1 ? true : false);
            setContractorChecked(preferences.is_contractor == 1 ? true : false);
            setVolunteerChecked(preferences.is_volunteer == 1 ? true : false);
            setHourlyRate(preferences.desired_hourly_rate);
            setMonthlySalary(preferences.desired_monthly_salary);
            setAvailableStartDate(preferences.available_start_date);
          }
        } else {
          console.error("Invalid response format:", response);
        }
        axios
          .get(`/get-availability/` + userId)
          .then((res) => {
            console.log(res, " response");
            if (res.data && res.message === "Success") {
              const availabilityData = res.data.workforce_availability;

              // Update state with the fetched skills
              setPreferredAvailability(availabilityData);
              console.log(availabilityData, "availability data");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    } else {
      console.error("WorkUser is null");
    }
  };

  // Function to extract and store data for each day
  const processAvailabilityData = () => {
    preferredAvailability?.forEach((availability) => {
      const {
        day_of_week,
        start_time,
        end_time,
        unavailable,
        available_anytime,
      } = availability;

      //

      // Handle "unavailable" and "Available anytime" cases
      let formattedStartTime = "";
      let formattedEndTime = "";

      if (start_time === "unavailable") {
        formattedStartTime = "unavailable";
        formattedEndTime = null; // Set a default end time for unavailable
      } else if (start_time === "Available anytime") {
        formattedStartTime = "available_anytime";
        formattedEndTime = null; // Set a default end time for available anytime
      } else {
        // Parse start_time and end_time as strings
        const parseTime = (time) => {
          const [hours, minutes, seconds] = time.split(":").map(Number);
          return new Date(2000, 0, 1, hours, minutes, seconds || 0);
        };

        const startTime = parseTime(start_time);
        const endTime = end_time ? parseTime(end_time) : null; // Parse only if end_time is not null

        // Format the time using Intl.DateTimeFormat
        const timeOptions = {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        };
        formattedStartTime = startTime.toLocaleTimeString("en-US", timeOptions);
        formattedEndTime = endTime
          ? endTime.toLocaleTimeString("en-US", timeOptions)
          : null;
      }

      // Remove seconds from time values
      const selectedStartTime = start_time.slice(unavailable, 5); // Extract hours and minutes
      const selectedEndTime = end_time ? end_time.slice(0, 5) : null; // Extract hours and minutes

      // Set data for each day based on the day_of_week
      switch (day_of_week) {
        case "Monday":
          setMondayFromTime(formattedStartTime);
          setMondayToTime(formattedEndTime);
          break;
        case "Tuesday":
          setTuesdayFromTime(formattedStartTime);
          setTuesdayToTime(formattedEndTime);
          break;
        case "Wednesday":
          setWednesdayFromTime(formattedStartTime);
          setWednesdayToTime(formattedEndTime);
          break;
        case "Thursday":
          setThursdayFromTime(formattedStartTime);
          setThursdayToTime(formattedEndTime);
          break;
        case "Friday":
          setFridayFromTime(formattedStartTime);
          setFridayToTime(formattedEndTime);
          break;
        case "Saturday":
          setSaturdayFromTime(formattedStartTime);
          setSaturdayToTime(formattedEndTime);
          break;
        case "Sunday":
          setSundayFromTime(formattedStartTime);
          setSundayToTime(formattedEndTime);
          break;
        default:
          break;
      }
    });
  };
  // preferrences validation
  const validateHourlyRate = (value) => {
    const regex = /^\d{1,5}(\.\d{0,2})?$/;
    return regex.test(value);
  };

  const validateMonthlySalary = (value) => {
    const regex = /^\d{1,6}(\.\d{0,2})?$/;
    return regex.test(value);
  };

  const handleHourlyRateChange = (e) => {
    const newValue = e.target.value;
    if (validateHourlyRate(newValue) || newValue === "") {
      setHourlyRate(newValue);
    }
  };

  const handleMonthlySalaryChange = (e) => {
    const newValue = e.target.value;
    if (validateMonthlySalary(newValue) || newValue === "") {
      setMonthlySalary(newValue);
    }
  };
  const residentialplace = (place) => {
    const addressComponents = place?.address_components;
    console.log(place);
    const { lat, lng } = place.geometry.location;
    const streetNumberComponent = addressComponents?.find((component) =>
      component.types.includes("street_number")
    );
    const streetRouteComponent = addressComponents?.find((component) =>
      component.types.includes("route")
    );
    const unitComponent = place?.addressComponents?.find((component) =>
      component.types.includes("subpremise")
    );
    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );
    const countryComponent = addressComponents?.find((component) =>
      component.types.includes("country")
    );

    let selectedStreetNumber = "";
    if (streetNumberComponent) {
      selectedStreetNumber = streetNumberComponent.long_name;
    }
    let selectedUnitNumber = "";
    if (unitComponent) {
      selectedUnitNumber = unitComponent.long_name;
    }
    let selectedStreetRoute = "";
    if (streetRouteComponent) {
      selectedStreetRoute = streetRouteComponent.long_name;
    }
    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
    }
    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name;
    }
    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }
    let selectedcountry = "";
    if (countryComponent) {
      selectedcountry = countryComponent.long_name;
    }

    setSelectedcity(
      `${selectedUnitNumber} ${selectedStreetNumber} ${selectedStreetRoute} `
    );
    setselectedSuburb(selectedSuburb + ", " + selectedState);
    setselectedPostcode(selectedPostcode);
    setCountry(selectedcountry);
    setAddress(place?.formatted_address);
    setLatitude(lat());
    setLongitude(lng());
  };

  const isValidMobileNumber = (number) => {
    // Regular expressions for US mobile, US toll-free, and Australian mobile/toll-free numbers
    const usRegex = /^(?:\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/; // US mobile
    const usTollFreeRegex = /^(?:\+?1)?(800|888|877|866|855|844|833)\d{7}$/; // US toll-free
    const auRegex = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/; // Australian mobile
    const auTollFreeRegex = /^(?:\+?61|0)?1800(?:[ -]?[0-9]){6}$/; // Australian toll-free
  
    // Check if the number matches any of the mobile or toll-free formats
    return (
      usRegex.test(number) ||
      usTollFreeRegex.test(number) ||
      auRegex.test(number) ||
      auTollFreeRegex.test(number)
    );
  };
  

  const handleNextButtonClick = () => {
    // Validate the company name
    if (!firstname) {
      toast.error("Please enter your first name.");
      return;
    }

    if (!lastname) {
      toast.error("Please enter your last name.");
      return;
    }

    if (!profile_title) {
      toast.error("Please enter your profile title");
      return;
    }

    // Check if required fields are not empty or null
    // if (!isValidMobileNumber(phone_number)) {
    //   toast.error(
    //     "Please enter a valid mobile number for the US or Australia."
    //   );
    //   return;
    // }
console.log(address, " full address")
    if (!address) {
      toast.error("Please enter your location.");
      return;
    }
    console.log("bdul");
    //proceed to next step if all conditions are met
    if (
      firstname?.trim() &&
      lastname?.trim() &&
      profile_title?.trim() &&
      address
    ) {
      console.log("bdul");
      toggleTab(activeTab + 1, 30);
    }
  };

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  function toggleTab(tab, value) {
    //validate employee-number
    const skillsJsonString = JSON.stringify(selectedSkills);
    if (tab == 2) {
      var formData = {
        id: authUserId,
        firstname: firstname,
        lastname: lastname,
        profile_title: profile_title,
        email: email,
        mobile: phone_number,
        address: address,
        user_skills: skillsJsonString,
        suburb: selectedCity,
        longitude: longitude,
        latitude: latitude,
        postcode: selectedPostcode,
        country: country,
      };
      console.log(formData, "formData");
      axios
        .post("/add-user-profile", formData)
        .then((res) => {
          console.log(res);
          handleChangeTab(tab, value);
        })
        .then((err) => {
          console.log(err);
        });
    } else if (tab == 3) {
      if (!hourlyRate || hourlyRate.trim() === "") {
        toast.error("Hourly rate is required.");

        return;
      }
      let data = {
        id: authUserId,
        user_industries: selectedIndustry,
        hourly_rate: hourlyRate,
        monthly_salary: monthlySalary,
        start_date: availableStartDate,
        employee: employeeChecked ? 1 : 0,
        contractor: contractorChecked ? 1 : 0,
        volunteer: volunteerChecked ? 1 : 0,
      };
      axios
        .post("/add-side-section", data)
        .then((res) => {
          if (res.status == "Success") {
            toast.success(res.message);

            handleChangeTab(tab, value);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (tab == 4) {
      const validateDay = (day, isAvailable, fromTime, toTime) => {
        if (
          isAvailable &&
          fromTime !== "unavailable" &&
          fromTime !== "available_anytime" &&
          (toTime === null || toTime === "default")
        ) {
          console.log("validate true");

          // setShowToast(true);
          toast.error(
            'Please select both "From Time" and "To Time" for all selected days.'
          );

          return false;
        }
        return true;
      };

      if (
        validateDay(
          "Monday",
          isMondayAvailable,
          mondayFromTime,
          mondayToTime
        ) &&
        validateDay(
          "Tuesday",
          isTueAvailable,
          tuesdayFromTime,
          tuesdayToTime
        ) &&
        validateDay(
          "Wednesday",
          isWedAvailable,
          wednesdayFromTime,
          wednesdayToTime
        ) &&
        validateDay(
          "Thursday",
          isThuAvailable,
          thursdayFromTime,
          thursdayToTime
        ) &&
        validateDay("Friday", isFriAvailable, fridayFromTime, fridayToTime) &&
        validateDay(
          "Saturday",
          isSatAvailable,
          saturdayFromTime,
          saturdayToTime
        ) &&
        validateDay("Sunday", isSunAvailable, sundayFromTime, sundayToTime)
      ) {
        const data = {
          id: authUserId,
          working_hours: [
            {
              day_of_week: "Monday",
              is_available: isMondayAvailable ? "true" : "false",
              fromTime: mondayFromTime,
              toTime: mondayToTime,
            },
            {
              day_of_week: "Tuesday",
              is_available: isTueAvailable ? "true" : "false",
              fromTime: tuesdayFromTime,
              toTime: tuesdayToTime,
            },
            {
              day_of_week: "Wednesday",
              is_available: isWedAvailable ? "true" : "false",
              fromTime: wednesdayFromTime,
              toTime: wednesdayToTime,
            },
            {
              day_of_week: "Thursday",
              is_available: isThuAvailable ? "true" : "false",
              fromTime: thursdayFromTime,
              toTime: thursdayToTime,
            },
            {
              day_of_week: "Friday",
              is_available: isFriAvailable ? "true" : "false",
              fromTime: fridayFromTime,
              toTime: fridayToTime,
            },
            {
              day_of_week: "Saturday",
              is_available: isSatAvailable ? "true" : "false",
              fromTime: saturdayFromTime,
              toTime: saturdayToTime,
            },
            {
              day_of_week: "Sunday",
              is_available: isSunAvailable ? "true" : "false",
              fromTime: sundayFromTime,
              toTime: sundayToTime,
            },
          ],
        };
        console.log("data", data);

        axios
          .post("/add-availability", data)
          .then((response) => {
            // Parse each JSON string into an object
            const jsonObjectArray = response.map(JSON.parse);

            // Extract the first element
            const firstElement = jsonObjectArray[0];
            if (firstElement.error === 0) {
              toast.success(firstElement.message, { theme: "light" });
              // setstatelocation(res.state);
              // setressuburb(res.suburb);
              handleChangeTab(tab, value);
            } else {
              toast.error(firstElement.message, { theme: "light" });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }

  const handleChangeTab = (tab, value) => {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
    setRegistrationProgress(value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Business Registration"
            pageTitle="Business Profile"
          />
          <Card className="" style={{ minHeight: "calc(100vh - 190px)" }}>
            <CardBody>
              <Row>
                <Col xxl={3} className="mt-3">
                  <div className="ms-3 me-3">
                    <h5 className="card-title  mt-3 mb-0">
                      Complete Your Profile
                    </h5>
                    {console.log(registrationProgress, "registration percent")}
                    <div
                      className={`progress ps-0 animated-progress mt-4 ${
                        registrationProgress === 30
                          ? "custom-progress-30"
                          : registrationProgress === 70
                          ? "custom-progress-70"
                          : registrationProgress === 100
                          ? "custom-progress-100"
                          : "custom-progress-0"
                      } progress-label`}
                    >
                      <div
                        className={`progress-bar`}
                        role="progressbar"
                        style={{
                          width: `${registrationProgress}%`,
                          backgroundColor: "#48008A",
                        }}
                        aria-valuenow={registrationProgress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {/* <div className="label">{`${registrationProgress}%`}</div> */}
                      </div>
                    </div>
                    {/* <div className="mt-4">
                      <img src={logo} height={150}></img>
                    </div> */}
                  </div>
                </Col>

                <Col xxl={9} className="mt-3">
                  <div className="ms-3 me-3">
                    <Form action="#" className="form-steps">
                      <div className="hstack gap-5 flex-start">
                        <div className=" pt-3 pb-4 mb-1">
                          <h5>Signup Your Account</h5>
                        </div>

                        <div className="progress-nav mb-4 flex-1">
                          <Progress
                            value={progressBarWidth}
                            style={{ height: "1px" }}
                          />

                          <Nav
                            className="nav-pills progress-bar-tab custom-nav"
                            role="tablist"
                          >
                            <NavItem>
                              <NavLink
                                to="javascript;"
                                id="pills-gen-info-tab"
                                className={classnames({
                                  active: activeTab === 1,
                                  done: activeTab <= 5 && activeTab >= 2,
                                })}
                                onClick={(e) => {
                                  e.preventDefault();
                                  // toggleTab(1, 0);
                                }}
                                tag="button"
                              >
                                <i className="bold ri-check-line text-white fs-16"></i>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                to="javascript;"
                                id="pills-gen-info-tab"
                                className={classnames({
                                  active: activeTab === 2,
                                  done: activeTab <= 5 && activeTab >= 3,
                                })}
                                onClick={(e) => {
                                  e.preventDefault();
                                  // toggleTab(2, 30);
                                }}
                                tag="button"
                              >
                                <i className="bold ri-check-line text-white fs-16"></i>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                to="javascript;"
                                id="pills-gen-info-tab"
                                className={classnames({
                                  active: activeTab === 3,
                                  done: activeTab <= 5 && activeTab >= 4,
                                })}
                                onClick={(e) => {
                                  e.preventDefault();
                                  // toggleTab(3, 70);
                                }}
                                tag="button"
                              >
                                <i className="bold ri-check-line text-white fs-16"></i>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                to="javascript;"
                                id="pills-gen-info-tab"
                                className={classnames({
                                  active: activeTab === 4,
                                  done: activeTab <= 5 && activeTab == 4,
                                })}
                                onClick={(e) => {
                                  e.preventDefault();
                                  //toggleTab(4, 100);
                                }}
                                tag="button"
                              >
                                <i className="bold ri-check-line text-white fs-16"></i>
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                      </div>

                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={1}>
                          <div
                            style={{
                              minHeight: "60vh",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Row form>
                              <Col md={6}>
                                <Label for="firstName form-label h5">
                                  First Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Label>

                                <Input
                                  id="firstName"
                                  value={firstname}
                                  onChange={(e) => {
                                    const text = e.target.value;

                                    setfirstname(text);
                                  }}
                                />
                              </Col>

                              <Col md={6}>
                                <Label for="lastName form-label h5">
                                  Last Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Label>

                                <Input
                                  id="lastName"
                                  value={lastname}
                                  onChange={(e) => {
                                    const text = e.target.value;
                                    setlastname(text);
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row form className="mt-3">
                              <Col md={6}>
                                <Label for="firstName form-label h5">
                                  Email
                                </Label>

                                <Input id="email" value={email} disabled />
                              </Col>

                              <Col md={6}>
                                <Label for="lastName form-label h5">
                                  Contact Number{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Label>

                                <Input
                                  id="phoneNumber"
                                  value={phone_number}
                                  type="number"
                               
                                  onChange={(e) => {
                                    const text = e.target.value;
                                    if (text.length <= 15) {
                                      setContactNumber(text);
                                    }
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row form className="mt-3">
                              <Col md={6}>
                                <Label for="firstName form-label  h5">
                                  Address{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Label>

                                <GoogleAutocomplete
                                  className={`${
                                    addressError ? "is-invalid" : ""
                                  }`}
                                  apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                  value={address}
                                  onChange={(e) => {
                                    setAddress(e.target.value);
                                    setAddressError("");
                                  }}
                                  onPlaceSelected={(place) =>
                                    residentialplace(place)
                                  }
                                  options={{
                                    types: ["address"],
                                    componentRestrictions: {
                                      country: "au",
                                    },
                                  }}
                                  style={{
                                    width: "100%",
                                    border: "1px solid #ccc",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    position: "relative",
                                    zIndex: 9999,
                                  }}
                                />
                              </Col>
                              <Col md={6}>
                                <Label for="professionalHeadline form-label h5">
                                  Professional Headline{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Label>

                                <Input
                                  id="professionalHeadline"
                                  value={profile_title}
                                  onChange={(e) => {
                                    const text = e.target.value;
                                    setprofiletitle(text);
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col md={6}>
                                <Label for="skills form-label h5">Skills</Label>

                                <Select
                                  value={selectedSkills}
                                  isMulti={true}
                                  isClearable={true}
                                  onChange={(selectedOption) => {
                                    handleSkillsMulti(selectedOption);
                                  }}
                                  options={skills?.map((skill) => ({
                                    value: skill.id,
                                    label: skill.name,
                                  }))}
                                />
                              </Col>
                            </Row>
                          </div>
                          <div className="d-flex justify-content-end text-center gap-3 mt-3 mb-2">
                            <button
                              type="button"
                              className="btn btn-primary  right text-white  nexttab "
                              style={{ zIndex: "999" }}
                              onClick={() => {
                                handleNextButtonClick();
                              }}
                            >
                              Next Step
                            </button>
                            {isLoading ? (
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  background: "rgba(255, 255, 255, 0.8)",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  zIndex: 9999,
                                }}
                              >
                                <Loader />
                              </div>
                            ) : null}
                          </div>
                        </TabPane>

                        <TabPane tabId={2}>
                          <div className="mt-3">
                            <Row>
                              <Col lg={12}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="industries"
                                    className="form-label"
                                  >
                                    Industries
                                  </Label>
                                  <Select
                                    value={selectedIndustry}
                                    id="industries"
                                    isMulti={true}
                                    isClearable={true}
                                    onChange={(selectedOption) => {
                                      handleIndustryMulti(selectedOption);
                                    }}
                                    options={allIndustryNames?.map(
                                      (industry) => ({
                                        value: industry.id,
                                        label: industry.name,
                                      })
                                    )}
                                  />
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="workType"
                                    className="form-label "
                                  >
                                    Preferred Work Type
                                  </Label>
                                  <div className="hstack gap-3 mt-1">
                                    <div className="form-check">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="employee"
                                        checked={employeeChecked}
                                        onChange={() =>
                                          setEmployeeChecked(!employeeChecked)
                                        }
                                      />
                                      <Label
                                        htmlFor="employee"
                                        className="form-check-label "
                                      >
                                        Employee
                                      </Label>
                                    </div>
                                    <div className="form-check">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="contractor"
                                        checked={contractorChecked}
                                        onChange={() =>
                                          setContractorChecked(
                                            !contractorChecked
                                          )
                                        }
                                      />
                                      <Label
                                        htmlFor="contractor"
                                        className="form-check-label "
                                      >
                                        Contractor
                                      </Label>
                                    </div>
                                    <div className="form-check">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="volunteer"
                                        checked={volunteerChecked}
                                        onChange={() =>
                                          setVolunteerChecked(!volunteerChecked)
                                        }
                                      />
                                      <Label
                                        htmlFor="volunteer"
                                        className="form-check-label "
                                      >
                                        Volunteer
                                      </Label>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="hourlyRateInput"
                                    className="form-label"
                                  >
                                    Hourly rate
                                  </Label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="hourlyRateInput"
                                    value={hourlyRate}
                                    onChange={handleHourlyRateChange}
                                    pattern="\d{1,5}(\.\d{0,2})?$"
                                    // step="0.01" // Set the step to 0.01 for two decimal places
                                  />
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="monthlySalaryInput"
                                    className="form-label"
                                  >
                                    Monthly Salary
                                  </Label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="monthlySalaryInput"
                                    value={monthlySalary}
                                    onChange={handleMonthlySalaryChange}
                                    pattern="\d{1,6}(\.\d{0,2})?$"
                                  />
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Available Start Date
                                  </Label>
                                  <Flatpickr
                                    className="form-control"
                                    value={
                                      availableStartDate !== null &&
                                      availableStartDate
                                    } // Pass the date string directly
                                    options={{
                                      dateFormat: "Y-m-d", // Specify the format of the input date string
                                      altInput: true,
                                      altFormat: "d-m-Y", // Set the desired format for display
                                    }}
                                    onChange={(selectedDates) => {
                                      if (selectedDates.length === 0) {
                                        // If date is cleared, set availableStartDate to null
                                        setAvailableStartDate(null);
                                      } else {
                                        // Update state with formatted date
                                        setAvailableStartDate(
                                          moment(selectedDates[0]).format(
                                            "YYYY-MM-DD"
                                          )
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg={12}>
                                <Tooltip
                                  target="infoTooltip"
                                  isOpen={tooltipOpen}
                                  toggle={toggleTooltip}
                                  placement="top"
                                >
                                  Current Availability is calculated based on
                                  your Preferred Availability.
                                </Tooltip>
                                <div className="mb-3">
                                  <div className="hstack gap-1">
                                    <Label className="form-label">
                                      Current Availability
                                    </Label>
                                    <i
                                      id="infoTooltip"
                                      size={15}
                                      style={{ color: "#4db9dc" }}
                                      className="ri-information-line mb-1"
                                      onClick={toggleTooltip}
                                    ></i>
                                  </div>
                                  <input
                                    type="number"
                                    disabled
                                    className="form-control"
                                    value={
                                      currentAvailability?.total_hours
                                        ? currentAvailability?.total_hours
                                        : 0
                                    }
                                    onChange={(e) => {
                                      setCurrentAvailability(e.target.value);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="d-flex justify-content-between gap-3 mt-5">
                            <button
                              type="button"
                              className="btn  btn-primary text-decoration-none"
                              onClick={() => {
                                toggleTab(activeTab - 1, 0);
                              }}
                            >
                              Previous
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary  right text-white  nexttab"
                              style={{ zIndex: "999" }}
                              onClick={() => {
                                toggleTab(activeTab + 1, 70);
                              }}
                            >
                              Next step
                            </button>
                          </div>
                        </TabPane>

                        <TabPane tabId={3}>
                          <Row>
                            <Col md={3}>
                              <div className="form-check mb-3 m-2">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="mondaycheck"
                                  // defaultChecked
                                  checked={isMondayAvailable}
                                  onChange={() => {
                                    if (!isMondayAvailable) {
                                      setMondayFromTime("available_anytime");
                                    } else {
                                      setMondayFromTime("unavailable");
                                    }
                                    setIsMondayAvailable(!isMondayAvailable);
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  for="mondaycheck"
                                >
                                  Monday
                                </Label>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <select
                                  className="form-select"
                                  value={mondayFromTime || "default"}
                                  onChange={(e) => {
                                    setMondayFromTime(e.target.value);
                                    if (e.target.value === "unavailable") {
                                      setIsMondayAvailable(false);
                                    }
                                  }}
                                  // disabled={!isMondayAvailable}
                                >
                                  <option value="default" disabled>
                                    Select a time
                                  </option>
                                  {timeOptions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            {isMondayAvailable &&
                            mondayFromTime !== "unavailable" &&
                            mondayFromTime !== "available_anytime" ? (
                              <Col md={4}>
                                <div className="mb-3">
                                  <select
                                    className="form-select"
                                    value={mondayToTime || "default"}
                                    onChange={(e) => {
                                      setMondayToTime(e.target.value);
                                      if (e.target.value === "unavailable") {
                                        setIsMondayAvailable(false);
                                      }
                                    }}
                                  >
                                    <option value="default" disabled>
                                      Select a time
                                    </option>
                                    {timeOptions
                                      .filter(
                                        (option) =>
                                          option.value > mondayFromTime &&
                                          option.value !== "unavailable" &&
                                          option.value !== "available_anytime"
                                      )
                                      .map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </Col>
                            ) : (
                              <Col md={4}></Col>
                            )}
                          </Row>
                          <Row>
                            <Col md={3}>
                              <div className="form-check mb-3 m-2">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck2"
                                  // defaultChecked
                                  checked={isTueAvailable}
                                  onChange={() => {
                                    if (!isTueAvailable) {
                                      setTuesdayFromTime("available_anytime");
                                    } else {
                                      setTuesdayFromTime("unavailable");
                                    }
                                    setIsTueAvailable(!isTueAvailable);
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck2"
                                >
                                  Tuesday
                                </Label>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <select
                                  className="form-select"
                                  value={tuesdayFromTime || "default"}
                                  onChange={(e) => {
                                    setTuesdayFromTime(e.target.value);
                                    if (e.target.value === "unavailable") {
                                      setIsTueAvailable(false);
                                    }
                                  }}
                                  // disabled={!isTueAvailable}
                                >
                                  <option value="default" disabled>
                                    Select a time
                                  </option>
                                  {timeOptions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            {isTueAvailable &&
                              tuesdayFromTime !== "unavailable" &&
                              tuesdayFromTime !== "available_anytime" && (
                                <Col md={4}>
                                  <div className="mb-3">
                                    <select
                                      className="form-select"
                                      value={tuesdayToTime || "default"}
                                      onChange={(e) => {
                                        setTuesdayToTime(e.target.value);
                                        if (e.target.value === "unavailable") {
                                          setIsTueAvailable(false);
                                        }
                                      }}
                                    >
                                      <option value="default" disabled>
                                        Select a time
                                      </option>
                                      {timeOptions
                                        .filter(
                                          (option) =>
                                            option.value > tuesdayFromTime &&
                                            option.value !== "unavailable" &&
                                            option.value !== "available_anytime"
                                        )
                                        .map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                              )}
                          </Row>
                          <Row>
                            <Col md={3}>
                              <div className="form-check mb-3 m-2">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck3"
                                  // defaultChecked
                                  checked={isWedAvailable}
                                  onChange={() => {
                                    if (!isWedAvailable) {
                                      setWednesdayFromTime("available_anytime");
                                    } else {
                                      setWednesdayFromTime("unavailable");
                                    }
                                    setIsWedAvailable(!isWedAvailable);
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck3"
                                >
                                  Wednesday
                                </Label>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <select
                                  className="form-select"
                                  value={wednesdayFromTime || "default"}
                                  onChange={(e) => {
                                    setWednesdayFromTime(e.target.value);
                                    if (e.target.value === "unavailable") {
                                      setIsWedAvailable(false);
                                    }
                                  }}
                                  // disabled={!isWedAvailable}
                                >
                                  <option value="default" disabled>
                                    Select a time
                                  </option>
                                  {timeOptions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            {isWedAvailable &&
                              wednesdayFromTime !== "unavailable" &&
                              wednesdayFromTime !== "available_anytime" && (
                                <Col md={4}>
                                  <div className="mb-3">
                                    <select
                                      className="form-select"
                                      value={wednesdayToTime || "default"}
                                      onChange={(e) => {
                                        setWednesdayToTime(e.target.value);
                                        if (e.target.value === "unavailable") {
                                          setIsWedAvailable(false);
                                        }
                                      }}
                                    >
                                      <option value="default" disabled>
                                        Select a time
                                      </option>
                                      {timeOptions
                                        .filter(
                                          (option) =>
                                            option.value > wednesdayFromTime &&
                                            option.value !== "unavailable" &&
                                            option.value !== "available_anytime"
                                        )
                                        .map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                              )}
                          </Row>
                          <Row>
                            <Col md={3}>
                              <div className="form-check mb-3 m-2">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck4"
                                  // defaultChecked
                                  checked={isThuAvailable}
                                  onChange={() => {
                                    if (!isThuAvailable) {
                                      setThursdayFromTime("available_anytime");
                                    } else {
                                      setThursdayFromTime("unavailable");
                                    }
                                    setIsThuAvailable(!isThuAvailable);
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck4"
                                >
                                  Thursday
                                </Label>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <select
                                  className="form-select"
                                  value={thursdayFromTime || "default"}
                                  onChange={(e) => {
                                    setThursdayFromTime(e.target.value);
                                    if (e.target.value === "unavailable") {
                                      setIsThuAvailable(false);
                                    }
                                  }}
                                  // disabled={!isThuAvailable}
                                >
                                  <option value="default" disabled>
                                    Select a time
                                  </option>
                                  {timeOptions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            {isThuAvailable &&
                              thursdayFromTime !== "unavailable" &&
                              thursdayFromTime !== "available_anytime" && (
                                <Col md={4}>
                                  <div className="mb-3">
                                    <select
                                      className="form-select"
                                      value={thursdayToTime || "default"}
                                      onChange={(e) => {
                                        setThursdayToTime(e.target.value);
                                        if (e.target.value === "unavailable") {
                                          setIsThuAvailable(false);
                                        }
                                      }}
                                    >
                                      <option value="default" disabled>
                                        Select a time
                                      </option>
                                      {timeOptions
                                        .filter(
                                          (option) =>
                                            option.value > thursdayFromTime &&
                                            option.value !== "unavailable" &&
                                            option.value !== "available_anytime"
                                        )
                                        .map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                              )}
                          </Row>
                          <Row>
                            <Col md={3}>
                              <div className="form-check mb-3 m-2">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  // id="formCheck5"
                                  defaultChecked
                                  checked={isFriAvailable}
                                  onChange={() => {
                                    if (!isFriAvailable) {
                                      setFridayFromTime("available_anytime");
                                    } else {
                                      setFridayFromTime("unavailable");
                                    }
                                    setIsFriAvailable(!isFriAvailable);
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck5"
                                >
                                  Friday
                                </Label>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <select
                                  className="form-select"
                                  value={fridayFromTime || "default"}
                                  onChange={(e) => {
                                    setFridayFromTime(e.target.value);
                                    if (e.target.value === "unavailable") {
                                      setIsFriAvailable(false);
                                    }
                                  }}
                                  // disabled={!isFriAvailable}
                                >
                                  <option value="default" disabled>
                                    Select a time
                                  </option>
                                  {timeOptions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            {isFriAvailable &&
                              fridayFromTime !== "unavailable" &&
                              fridayFromTime !== "available_anytime" && (
                                <Col md={4}>
                                  <div className="mb-3">
                                    <select
                                      className="form-select"
                                      value={fridayToTime || "default"}
                                      onChange={(e) => {
                                        setFridayToTime(e.target.value);
                                        if (e.target.value === "unavailable") {
                                          setIsFriAvailable(false);
                                        }
                                      }}
                                    >
                                      <option value="default" disabled>
                                        Select a time
                                      </option>
                                      {timeOptions
                                        .filter(
                                          (option) =>
                                            option.value > fridayFromTime &&
                                            option.value !== "unavailable" &&
                                            option.value !== "available_anytime"
                                        )
                                        .map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                              )}
                          </Row>
                          <Row>
                            <Col md={3}>
                              <div className="form-check mb-3 m-2">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck6"
                                  // defaultChecked
                                  checked={isSatAvailable}
                                  onChange={() => {
                                    if (!isSatAvailable) {
                                      setSaturdayFromTime("available_anytime");
                                    } else {
                                      setSaturdayFromTime("unavailable");
                                    }
                                    setIsSatAvailable(!isSatAvailable);
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck6"
                                >
                                  Saturday
                                </Label>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <select
                                  className="form-select"
                                  value={saturdayFromTime || "default"}
                                  onChange={(e) => {
                                    setSaturdayFromTime(e.target.value);
                                    if (e.target.value === "unavailable") {
                                      setIsSatAvailable(false);
                                    }
                                  }}
                                  // disabled={!isSatAvailable}
                                >
                                  <option value="default" disabled>
                                    Select a time
                                  </option>
                                  {timeOptions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            {isSatAvailable &&
                              saturdayFromTime !== "unavailable" &&
                              saturdayFromTime !== "available_anytime" && (
                                <Col md={4}>
                                  <div className="mb-3">
                                    <select
                                      className="form-select"
                                      value={saturdayToTime || "default"}
                                      onChange={(e) => {
                                        setSaturdayToTime(e.target.value);
                                        if (e.target.value === "unavailable") {
                                          setIsSatAvailable(false);
                                        }
                                      }}
                                    >
                                      <option value="default" disabled>
                                        Select a time
                                      </option>
                                      {timeOptions
                                        .filter(
                                          (option) =>
                                            option.value > saturdayFromTime &&
                                            option.value !== "unavailable" &&
                                            option.value !== "available_anytime"
                                        )
                                        .map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                              )}
                          </Row>
                          <Row>
                            <Col md={3}>
                              <div className="form-check mb-3 m-2">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck7"
                                  // defaultChecked
                                  checked={isSunAvailable}
                                  onChange={() => {
                                    if (!isSunAvailable) {
                                      setSundayFromTime("available_anytime");
                                    } else {
                                      setSundayFromTime("unavailable");
                                    }
                                    setIsSunAvailable(!isSunAvailable);
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck7"
                                >
                                  Sunday
                                </Label>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <select
                                  className="form-select"
                                  value={sundayFromTime || "default"}
                                  onChange={(e) => {
                                    setSundayFromTime(e.target.value);
                                    if (e.target.value === "unavailable") {
                                      setIsSunAvailable(false);
                                    }
                                  }}
                                  // disabled={!isSunAvailable}
                                >
                                  <option value="default" disabled>
                                    Select a time
                                  </option>
                                  {timeOptions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            {isSunAvailable &&
                              sundayFromTime !== "unavailable" &&
                              sundayFromTime !== "available_anytime" && (
                                <Col md={4}>
                                  <div className="mb-3">
                                    <select
                                      className="form-select"
                                      value={sundayToTime || "default"}
                                      onChange={(e) => {
                                        setSundayToTime(e.target.value);
                                        if (e.target.value === "unavailable") {
                                          setIsSunAvailable(false);
                                        }
                                      }}
                                    >
                                      <option value="default" disabled>
                                        Select a time
                                      </option>
                                      {timeOptions
                                        .filter(
                                          (option) =>
                                            option.value > sundayFromTime &&
                                            option.value !== "unavailable" &&
                                            option.value !== "available_anytime"
                                        )
                                        .map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                              )}
                          </Row>
                          <div className="d-flex justify-content-between mt-2">
                            <button
                              type="button"
                              className="btn btn-primary text-decoration-none"
                              onClick={() => {
                                toggleTab(activeTab - 1, 70);
                              }}
                            >
                              Previous
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary  right text-white  nexttab"
                              style={{ zIndex: "999" }}
                              onClick={() => {
                                toggleTab(activeTab + 1, 100);
                              }}
                            >
                              Next step
                            </button>
                          </div>
                          {showToast && (
                            <div
                              className="toast"
                              role="alert"
                              aria-live="assertive"
                              aria-atomic="true"
                            >
                              <div className="toast-body">
                                Please select both "From Time" and "To Time" for
                                all selected days.
                              </div>
                            </div>
                          )}
                        </TabPane>
                        <TabPane tabId={4}>
                          <h5 className="text-primary">
                            Preferred Work Locations
                          </h5>
                          <Row className="mb-4 align-items-end">
                            <Col lg={5} className="d-flex flex-column mt-2">
                              <Label className="form-label">Suburb</Label>
                              <GoogleAutocomplete
                                apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                value={selectedSuburb}
                                id="selectlocation"
                                onKeyUp={(e) => handleKeyUp(e, "suburb")}
                                onPlaceSelected={selectSuburbOption}
                                onChange={(e) => {
                                  setselectedSuburb(e.target.value);
                                }}
                                options={{
                                  types: ["(regions)"],
                                  componentRestrictions: {
                                    country: "au",
                                  },
                                }}
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "8px",
                                  borderRadius: "5px",
                                  //   position: "relative",
                                  zIndex: 0,
                                }}
                              />
                            </Col>

                            <Col lg={4} className="d-flex flex-column mt-2">
                              <Label className="form-label">Radius</Label>
                              <input
                                type="number" // Change type to 'text'
                                className="form-control"
                                value={radius}
                                onChange={handleRadiusChange}
                              />
                            </Col>
                            <Col lg={3}>
                              <div className="justify-content-end">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={submitlocation}
                                  disabled={isLoading} // Disable the button when the loader is active
                                >
                                  {isLoading ? <Spinner /> : "Add"}
                                </button>
                              </div>
                            </Col>
                          </Row>

                          {userlocation?.map((item, index) => {
                            return (
                              <>
                                {!item.isEditing && (
                                  <div
                                    className="location-container hstack mb-2 "
                                    key={index}
                                  >
                                    <div className="hstack col-6 gap-4 justify-content-between">
                                      <h5 className="mb-0">{item.location}</h5>
                                      <h6 className="mb-0">
                                        {item.radius_distance} Km
                                      </h6>
                                    </div>
                                    <div className="hstack col-4 gap-2 justify-content-end">
                                      <i
                                        className="ri-edit-2-line text-success"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: 18,
                                        }}
                                        onClick={() => t_location(item.id)}
                                      />
                                      <i
                                        className="bx bx-trash text-danger"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: 18,
                                        }}
                                        onClick={() => deletelocation(item)}
                                      />
                                    </div>
                                  </div>
                                )}
                                {item.isEditing && (
                                  <div className="mb-2 pb-2" key={index}>
                                    <div className="" key={index}>
                                      <h4 className="mb-2">
                                        Location {index + 1}
                                      </h4>
                                      <div className="justify-content-between align-items-center mb-2">
                                        <div className="education-details text-muted">
                                          <Row>
                                            <Col
                                              lg={12}
                                              className="d-flex flex-column mt-2"
                                            >
                                              <Label>Suburb</Label>
                                              <GoogleAutocomplete
                                                value={item.location}
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  setInputValue(value); // Update the state with the new value

                                                  const updatedData =
                                                    userlocation.map((task) =>
                                                      task.id === item.id
                                                        ? {
                                                            ...task,
                                                            location: value,
                                                          }
                                                        : task
                                                    );
                                                  setUserlocation(updatedData);
                                                }}
                                                id="editlocation"
                                                onKeyUp={(e) =>
                                                  handleKeyUp(
                                                    e,
                                                    "editsuburb",
                                                    item
                                                  )
                                                }
                                                onPlaceSelected={(place) =>
                                                  editlocationfield(place, item)
                                                }
                                                options={{
                                                  types: ["(regions)"],
                                                  componentRestrictions: {
                                                    country: "au",
                                                  },
                                                }}
                                                style={{
                                                  border: "1px solid #ccc",
                                                  padding: "10px",
                                                  borderRadius: "5px",
                                                  position: "relative",
                                                }}
                                              />
                                            </Col>

                                            <Col
                                              lg={12}
                                              className="d-flex flex-column mt-2"
                                            >
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  Radius
                                                </Label>
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  value={item.radius_distance}
                                                  onChange={handleeditradiuschange(
                                                    item
                                                  )}
                                                />
                                              </div>
                                            </Col>
                                          </Row>

                                          <Row
                                            style={{
                                              alignItems: "end",
                                              marginLeft: 8,
                                            }}
                                          >
                                            <Col>
                                              <div className="hstack gap-2 justify-content-end">
                                                <button
                                                  type="button"
                                                  className="btn btn-soft-danger"
                                                  onClick={() =>
                                                    t_location(item.id, true)
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn btn-soft-success"
                                                  onClick={() =>
                                                    savelocation(item)
                                                  }
                                                  disabled={isLoading}
                                                >
                                                  {isLoading ? (
                                                    <Spinner />
                                                  ) : (
                                                    "Update"
                                                  )}
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <hr className="calendar-hr me-5" />
                              </>
                            );
                          })}
                          <div className="mt-2 float-end">
                            <button
                              type="button"
                              className="btn btn-primary  right text-white  nexttab"
                              style={{ zIndex: "999" }}
                              onClick={() => {
                                submitCompletion();
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </TabPane>
                      </TabContent>
                    </Form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <ToastContainer />
        </Container>
        <div style={{ position: "absolute", bottom: "0px", right: "0px" }}>
          {/* <img src={halfLogo} height={200}></img> */}
        </div>
      </div>
    </React.Fragment>
  );
};
export default WorkForceSetup;
