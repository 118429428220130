import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  NavLink,
  Button,
  Label,
  Badge,
  ModalBody,
  Modal,
  UncontrolledCollapse,
  Collapse,
  Accordion,
  AccordionItem,
  ModalHeader,
  Spinner,
  Alert,
} from "reactstrap";

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { get_cookie } from "../../helpers/get_cookie";

import { ToastContainer, toast } from "react-toastify";

import JobView from "./JobView";

import searchworkforceimage from "../../assets/images/searchworkforceimage.png";
import industry from "../../assets/images/industry.png";
// RangeSlider
import "nouislider/distribute/nouislider.css";

import GoogleAutocomplete from "react-google-autocomplete";

const JobSearch = () => {
  const User_id = JSON.parse(get_cookie("workauthUser")).user_id;

  const [searchQuery, setSearchQuery] = useState();
  const [topSkillsList, setTopSkillsList] = useState();
  const [industriesList, setIndustriesList] = useState();
  const [listsAllSkills, setListsAllSkills] = useState();
  const [open, setOpen] = useState(false);
  const [isFilter, setIsFilter] = useState(true);
  const [jobs, setJobs] = useState(null);
  const [isReset, setIsReset] = useState(false);
  const [originalJobs, setOriginalJobs] = useState(null);
  const [isQuery, setIsQuery] = useState(false);
  const [showMoreList, setShowMoreList] = useState([]);
  const [openJobId, setOpenJobId] = useState();
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedRange, setSelectedRange] = useState(null);
  const [jobLocation, setJobLocation] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [skillSearchId, setSkillSearchId] = useState("");
  const [selectedIndustryMulti, setSelectedIndustryMulti] = useState(null);
  const [industryIds, setIndustryIds] = useState(null);
  const [skillIds, setSkillIds] = useState(null);
  const [selectedSkillsMulti, setSelectedSkillsMulti] = useState(null);

  const [minCost, setMinCost] = useState(0);
  const [maxCost, setMaxCost] = useState(1000);

  const [applyJobModal, setApplyJobModal] = useState(false);
  const [isApplying, setIsApplying] = useState(false);
  const [message, setMessage] = useState("");
  const [jobId, setJobId] = useState();
  const [jobData, setJobData] = useState();

  const [selectedSuburb, setselectedSuburb] = useState();
  const [suburbAlone, setselectedSuburbAlone] = useState();
  const [selectedPostcode, setselectedPostcode] = useState();
  const [selectedState, setselectedState] = useState([]);
  const [selectedLatitude, setselectedLatitude] = useState("");
  const [selectedLongitude, setselectedLongitude] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [radius, setRadius] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [lastSearchType, setLastSearchType] = useState("");
  const [jobsCount, setJobsCount] = useState(0);
  const [geoCount, setGeoCount] = useState(0);
  const [skillCount, setSkillCount] = useState(0);
  const [rangeCount, setRangeCount] = useState(0);
  const [multiSkillCount, setMultiSkillCount] = useState(0);
  const [industryCount, setIndustryCount] = useState(0);

  const loadMore = () => {
    setShowLoader(true);
    if (lastSearchType === "text-search") {
      const formData = {
        type: "text-search",
        search_value: searchQuery,
        user_id: User_id,
        count: jobsCount + 1,
      };
      if (searchQuery?.trim() !== "") {
        axios
          .post("/job-search-filter", formData)
          .then((res) => {
            setJobs((prevJobs) => [...prevJobs, ...res]);
            setJobsCount(jobsCount + 1);
            setShowLoader(false);
          })
          .catch((error) => {
            console.error("Error fetching text search results:", error);
            setShowLoader(false);
          });
      } else {
        setJobs([]);
        setShowLoader(false);
      }
    } else if (lastSearchType === "geo-search") {
      const formData = {
        user_id: User_id,
        selectedLatitude: selectedLatitude,
        selectedLongitude: selectedLongitude,
        radius: radius,
        type: "geo-search",
        count: geoCount + 1,
        selectedSuburb: selectedSuburb,
      };
      if (selectedSuburb?.trim() !== "") {
        axios
          .post("/job-search-filter", formData)
          .then((res) => {
            setJobs((prevJobs) => [...prevJobs, ...res]);
            setGeoCount(geoCount + 1);
            setShowLoader(false);
          })
          .catch((error) => {
            console.error("Error fetching geo search results:", error);
            setShowLoader(false);
          });
      } else {
        setJobs([]);
        setShowLoader(false);
      }
    } else if (lastSearchType === "skill-search") {
      // console.log(selectedSkill,"skill-search");
      const formData = {
        industry_id: industryIds,
        location_value: jobLocation,
        skills_id: [{ value: selectedSkill }],
        range: [minCost, maxCost],
        user_id: User_id,
        type: "text-search",
        count: skillCount + 1,
      };
      if (selectedSkill !== "") {
        axios
          .post("job-search-filter", formData)
          .then((res) => {
            setJobs((prevJobs) => [...prevJobs, ...res]);
            setSkillCount(skillCount + 1);
            setShowLoader(false);
          })
          .catch((e) => {
            console.error("Error fetching skill search results:", e);
          });
      } else {
        setJobs([]);
        setShowLoader(false);
      }
    } else if (lastSearchType === "range-search") {
      // console.log(selectedRange,"range-search");
      const formData = {
        industry_id: industryIds,
        skills_id: skillIds,
        location_value: jobLocation,
        range: selectedRange,
        user_id: User_id,
        type: "text-search",
        count: rangeCount + 1,
      };
      if (selectedRange !== "") {
        axios
          .post("/job-search-filter", formData)
          .then((res) => {
            setJobs((prevJobs) => [...prevJobs, ...res]);
            setRangeCount(rangeCount + 1);
            setShowLoader(false);
          })
          .catch((e) => {
            console.error("Error fetching range search results:", e);
          });
      } else {
        setJobs([]);
        setShowLoader(false);
      }
    } else if (lastSearchType === "industry-search") {
      // console.log(industryIds,"industry-searchs");
      const formData = {
        location_value: jobLocation,
        range: [minCost, maxCost],
        skills_id: skillIds,
        industry_id: industryIds,
        user_id: User_id,
        type: "text-search",
        count: industryCount + 1,
      };
      if (industryIds !== "") {
        // Call the Axios request with the updated formData
        axios
          .post("/job-search-filter", formData)
          .then((res) => {
            setJobs((prevJobs) => [...prevJobs, ...res]);
            setIndustryCount(industryCount + 1);
            setShowLoader(false);
          })
          .catch((e) => {
            console.error("Error fetching industry search results:", e);
          });
      } else {
        setJobs([]);
        setShowLoader(false);
      }
    } else if (lastSearchType === "multi-skill-search") {
      // console.log(skillIds,"multi-skill-search");
      const formData = {
        location_value: jobLocation,
        range: [minCost, maxCost],
        industry_id: industryIds,
        skills_id: skillIds,
        user_id: User_id,
        type: "text-search",
        count: multiSkillCount + 1,
      };
      if (skillIds !== "") {
        // Call the Axios request with the updated formData
        axios
          .post("/job-search-filter", formData)
          .then((res) => {
            setJobs((prevJobs) => [...prevJobs, ...res]);
            setMultiSkillCount(multiSkillCount + 1);
            setShowLoader(false);
            // setisLoading(false);
          })
          .catch((e) => {
            console.error("Error fetching multi skill search results:", e);
          });
      } else {
        setJobs([]);
        setShowLoader(false);
      }
    }
  };

  function tog_ApplyNowModal(jobId) {
    JobDesc(jobId);
    setJobId(jobId);
    setApplyJobModal(!applyJobModal);
  }

  function tog_applyJobDetail(jobId = null) {
    if (jobId) {
      JobDesc(jobId);
      setJobId(jobId);
    }
    setApplyJobModal(!applyJobModal);
  }

  useEffect(() => {
    LoadData();
  }, []);

  useEffect(() => {
    setisLoading(true);
    // Fetch suggested jobs based on user skills
    const fetchSuggestedJobs = async () => {
      try {
        const postData = {
          user_id: User_id,
          initial_value: "initial",
          type: "text-search",
          count: 0,
        };

        await axios.post("/job-search-filter", postData).then((res) => {
          // Set originalJobs to the full list of jobs
          setOriginalJobs(res);

          // Set jobs to the suggested jobs
          setJobs(res);

          setisLoading(false);
        });
      } catch (error) {
        setisLoading(false);
        console.error("Error fetching suggested jobs:", error);
      }
    };

    fetchSuggestedJobs();
  }, [User_id]);

  // Use useEffect to make the initial API call and listen for changes in jobLocation
  useEffect(() => {
    if (jobLocation) {
      setisLoading(true);
      // Make the API call here
      const formData = {
        industry_id: industryIds,
        skills_id: skillIds,
        location_value: jobLocation,
        range: [minCost, maxCost],
        user_id: User_id,
        type: "text-search",
        count: 0,
      };

      axios
        .post("/job-search-filter", formData)
        .then((res) => {
          setJobs(res);
          setisLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching jobs:", error);
        })
        .finally(() => {
          setisLoading(false);
        });
    }
  }, [jobLocation]);

  useEffect(() => {
    onUpdate([0, 1000], "initial");
  }, []);

  const LoadData = () => {
    setisLoading(true);
    topSkills();
    listIndustries();
    listAllSkills();
    setisLoading(false);
  };

  const applyJob = (message) => {
    setIsApplying(true);
    const formData = {
      user_id: User_id,
      business_id: jobData?.business_id,
      job_vacancy_id: jobId,
      message: message,
    };

    axios
      .post("/send-job-application", formData)
      .then((res) => {
        if (res.status == "Success") {
          toast.success(res.message);
          setApplyJobModal(false);
          ReLoadData();
          setIsApplying(false);
        } else {
          toast.error(res.error);
          setIsApplying(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsApplying(false);
      });
  };

  const JobDesc = (jobID) => {
    const jobDetail = {
      job_id: jobID,
      user_id: User_id,
    };

    axios
      .post("/job-description-data", jobDetail)
      .then((res) => {
        if (res.message === "Success") {
          setJobData(res.Job_search);
          // setIsLoading(false);
        }
      })
      .then((err) => {
        console.log(err);
      });
  };

  const topSkills = () => {
    axios
      .get("skill-list")
      .then((res) => {
        setTopSkillsList(res.skill_list);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  const listIndustries = () => {
    axios
      .get("industries-list")
      .then((res) => {
        setIndustriesList(res.industries_list);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  const listAllSkills = () => {
    axios
      .get("list-all-skills")
      .then((res) => {
        setListsAllSkills(res.skills_list);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const skillFilter = (id) => {
    setLastSearchType("skill-search");
    setisLoading(true);

    setSelectedSkill(id);
    const formData = {
      industry_id: industryIds,
      location_value: jobLocation,
      skills_id: [{ value: id }],
      range: [minCost, maxCost],
      user_id: User_id,
      type: "text-search",
      count: 0,
    };
    axios
      .post("job-search-filter", formData)
      .then((res) => {
        setJobs(res.Job_search);
        setSkillCount(0);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const toggleShowMore = (index) => {
    const updatedShowMoreList = [...showMoreList];
    updatedShowMoreList[index] = !updatedShowMoreList[index];
    setShowMoreList(updatedShowMoreList);
  };

  const toggleDefaultCanvas = () => {
    setOpen(!open);
  };

  const toggleFilter = () => {
    setIsFilter(!isFilter);
  };

  const ReLoadData = () => {
    setisLoading(true);

    const formData = {
      search_value: searchQuery,
      industry_id: industryIds,
      skills_id: skillIds,
      location_value: jobLocation,
      range: [minCost, maxCost],
      user_id: User_id,
      type: "text-search",
      count: 0,
    };
    axios
      .post("/job-search-filter", formData)
      .then((res) => {
        setJobs(res.Job_search);
        setOriginalJobs(res.Job_search);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  // const handleSearch = () => {
  //   setLastSearchType("text-search");
  //   setisLoading(true);

  //   const formData = {
  //     type: "text-search",
  //     search_value: searchQuery,
  //     user_id: User_id,
  //     count: 0,
  //   };
  //   if (searchQuery?.trim() !== "") {
  //     axios
  //       .post("/job-search-filter", formData)
  //       .then((res) => {
  //         setJobs(res.Job_search);
  //         // setOriginalJobs(res.Job_search);
  //         setisLoading(false);
  //         setJobsCount(0);
  //       })
  //   }
  //   else {
  //     setJobs([]);
  //     setisLoading(false);
  //   }
  // };

  useEffect(() => {
    if (!searchQuery && !selectedSuburb && !radius) {
      console.log("useEffect1")
      handleSearch();
    }
  }, [searchQuery, selectedSuburb, radius]);

  useEffect(() => {
    if (isReset) {
      console.log("useEffect2")
      handleSearch();
      setIsReset(false); // Reset the flag after handling search
    }
  }, [isReset]);

  const handleReset = () => {
    setSearchQuery("");
    setselectedSuburb("");
    setRadius("");
    setLastSearchType(null);
    setJobs([]);
    setIsReset(true);
  };

    // Function to call the Google Geocoding API to convert an address to lat/lng
    const geocodeAddress = async (address) => {
      const apiKey = "AIzaSyDPs4Niq_SNsZTPdNDNoOVfErDW2CQ4S3k"; // API key for Google Geocoding API
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${apiKey}`; // Construct the API request URL
  
      try {
        // Step 1: Fetch the geocoding data from the API
        const response = await fetch(url);
        const data = await response.json();
        console.log("Geocode API Response:", data);
  
        // Step 2: If the API response contains valid results, extract the location
        if (data.results && data.results.length > 0) {
          const location = data.results[0].geometry.location; // Extract latitude & longitude
          const addressComponents = data.results[0].address_components;
          const formattedAddressOG = data.results[0].formatted_address;
  
          // setSelectedLocation({
          //   latitude: location.lat,
          //   longitude: location.lng,
          // });
          // Extract address components
          const { suburb, state, postalCode, country, formattedAddress } =
            extractAddressDetails(addressComponents);
  
          // Log the retrieved latitude and longitude
          console.log(
            `geocodeAddress = Latitude: ${location.lat}, Longitude: ${location.lng}`
          );
  
          // Return the latitude, longitude, and formatted address
          return {
            latitude: location.lat,
            longitude: location.lng,
            formattedAddress: formattedAddress,
            state: state,
            suburb: suburb,
            postal_code: postalCode,
            country: country,
          };
        } else {
          // If no results from the API, handle the error
          console.error("No results from geocoding API");
          Alert("Error", "Unable to fetch location from the address.");
          return null;
        }
      } catch (error) {
        // Handle any errors during the API request
        console.error("Error during geocoding fetch:", error);
        Alert("Error", "Failed to fetch location from Google.");
        return null;
      }
    };
  
    /* ---- Helper Function for Extracting Address Components ---- */
    const extractAddressDetails = (addressComponents) => {
      console.log(addressComponents, "addressComponents");
      const suburb =
        addressComponents.find((component) =>
          component.types.includes("locality")
        )?.long_name || ""; // Default to empty string if not found
  
      const state =
        addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.short_name || ""; // Fallback to empty string
  
      const country =
        addressComponents.find((component) => component.types.includes("country"))
          ?.long_name || ""; // Fallback to empty string
  
      const postalCode =
        addressComponents.find((component) =>
          component.types.includes("postal_code")
        )?.long_name || ""; // Fallback to empty string
  
      // Rebuild the full formatted address excluding postal code
      const formattedAddress = `${suburb ? suburb + ", " : ""}${state}`;
  
      return { formattedAddress, suburb, state, postalCode, country };
    };
    /* ---- End of Helper Function ---- */
  

  const handleSearch = async () => {
    // Set loading state
    setisLoading(true);
    setSkillSearchId("");

    // Validate that both location and radius are provided together
    if (radius && !selectedSuburb) {
      toast.error("Both location and radius must be filled together.");
      setisLoading(false); // Stop loading if validation fails
      return;
    }

    // Determine the type of search
    let searchType = "";
    if (selectedSuburb && radius && searchQuery?.trim() !== "") {
      searchType = "sendall";
    } else if (selectedSuburb) {
      searchType = "geo-search";
    } else if (searchQuery?.trim() !== "") {
      searchType = "text-search";
    }

    // Get the full address, handle the case when it returns undefined
    const geocodedData = await geocodeAddress(selectedSuburb);

    console.log(geocodedData,'geocodedData')

    // if (
    //   !geocodedData ||
    //   !geocodedData.latitude ||
    //   !geocodedData.longitude ||
    //   !geocodedData.formattedAddress
    // ) {
    //   throw new Error("Invalid address data received");
    // }

    console.log(geocodedData,'geocodedData')

    // Set the search type
    setLastSearchType(searchType);

    // Prepare form data for the search
    const formData = {
      query: searchQuery || "",
      user_id: User_id,
      count: 0,
    };

    if (searchType === "geo-search" || searchType === "sendall") {
      const {
        latitude,
        longitude,
        formattedAddress,
        state,
        suburb,
        postal_code,
        country,
      } = geocodedData;

      setselectedSuburb(formattedAddress)
      formData.selectedLatitude = latitude;
      formData.selectedLongitude = longitude;
      formData.radius = radius || 1;
    }

    // Reset state and set loading
    setisLoading(true);

    // Perform the search
    axios
      .post("/job-search-filter", formData)
      .then((response) => {
        console.log(response, "response");

        if (
          (searchType == "geo-search" &&
            response.errors == "no location found") ||
          response.count == 0
        ) {
          toast.error("No jobs available in this Location.", {
            theme: "light",
          });
          setJobs([]);
          setIsQuery(false);
        } else {
          setJobs(response);
          setIsQuery(true);
        }
        setisLoading(false);
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        toast.error("An error occurred during the search.");
        setisLoading(false);
      });
  };

  const pastelColors = [
    "#fd99a8", // Plum
    "#FFA07A", // LightSalmon
    "#FFD700", // Gold
    "#90df90", // PaleGreen
    "#8bdddd", // PaleTurquoise
    "#af91e3", // Lavender
    "#f791eb", // LightPink
  ];

  const SingleOptions = [
    { value: "Watches", label: "Watches" },
    { value: "Headset", label: "Headset" },
    { value: "Sweatshirt", label: "Sweatshirt" },
    { value: "20% off", label: "20% off" },
    { value: "4 star", label: "4 star" },
  ];

  const onUpdate = (values) => {
    const minSalary = values[0];
    const maxSalary = values[1];

    // Update the displayed values in the input fields
    // document.getElementById("minCost").value = `${minSalary}`;
    // document.getElementById("maxCost").value = `${maxSalary}`;

    // Update the state
    setMinCost(minSalary);
    setMaxCost(maxSalary);
  };

  // range filter code(Nouislider)
  const rangeFilter = (values) => {
    setLastSearchType("range-search");
    setisLoading(true);
    setSelectedRange(values);
    const formData = {
      industry_id: industryIds,
      skills_id: skillIds,
      location_value: jobLocation,
      range: values,
      user_id: User_id,
      type: "text-search",
      count: 0,
    };
    axios
      .post("/job-search-filter", formData)
      .then((res) => {
        console.log(res.Job_search, "range-search");
        setJobs(res.Job_search);
        setRangeCount(0);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  // Range filter code(input field)
  const handleInputChange = (event, field) => {
    setLastSearchType("range-search");
    const value = event.target.value;
    setSelectedRange([minCost, maxCost]);
    if (!isNaN(value)) {
      setisLoading(true);
      const formData = {
        industry_id: industryIds,
        skills_id: skillIds,
        location_value: jobLocation,
        range: [minCost, maxCost],
        user_id: User_id,
        type: "text-search",
        count: 0,
      };
      axios
        .post("/job-search-filter", formData)
        .then((res) => {
          setJobs(res.Job_search);
          setRangeCount(0);
          // setisLoading(false);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setisLoading(false);
        });
    }
  };

  // Industry Multi select
  function handleIndustryMulti(selectedOptions) {
    setLastSearchType("industry-search");
    setisLoading(true);
    setSelectedIndustryMulti(selectedOptions);
    const selectedIndustryIds = selectedOptions?.map((industry) => ({
      value: industry.value,
    }));
    setIndustryIds(selectedIndustryIds);

    // Update the Axios request with the selected industry IDs
    const formData = {
      location_value: jobLocation,
      range: [minCost, maxCost],
      skills_id: skillIds,
      industry_id: selectedIndustryIds,
      user_id: User_id,
      type: "text-search",
      count: 0,
    };

    // Call the Axios request with the updated formData
    axios
      .post("/job-search-filter", formData)
      .then((res) => {
        setJobs(res.Job_search);
        setIndustryCount(0);
        // setisLoading(false);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setisLoading(false);
      });
  }

  // Map the response to options with 'value' and 'label'
  const industryOptions = industriesList?.map((industry) => ({
    value: industry.id,
    label: industry.name,
  }));

  // Skills Multi select
  function handleSkillsMulti(selectedOptions) {
    setLastSearchType("multi-skill-search");
    setisLoading(true);

    setSelectedSkillsMulti(selectedOptions);
    const selectedSkillIds = selectedOptions?.map((skill) => ({
      value: skill.value,
    }));

    setSkillIds(selectedSkillIds);

    // Update the Axios request with the selected skill IDs
    const formData = {
      location_value: jobLocation,
      range: [minCost, maxCost],
      industry_id: industryIds,
      skills_id: selectedSkillIds,
      user_id: User_id,
      type: "text-search",
      count: 0,
    };

    // Call the Axios request with the updated formData
    axios
      .post("/job-search-filter", formData)
      .then((res) => {
        setJobs(res.Job_search);
        setMultiSkillCount(0);
        // setisLoading(false);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setisLoading(false);
      });
  }

  // Map the response to options with 'value' and 'label'
  const skillsOptions = listsAllSkills?.map((skill) => ({
    value: skill.id,
    label: skill.name,
  }));

  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  const bookmarkBtn = (ele) => {
    if (ele.closest("button").classList.contains("active")) {
      ele.closest("button").classList.remove("active");
    } else {
      ele.closest("button").classList.add("active");
    }
  };

  // Job Locations
  const autocompleteService = useRef(null);
  const itemIdRef = useRef(null);

  const handleRadiusChange = (e) => {
    // Ensure the input is a valid positive integer with a maximum of 3 digits
    const inputValue = e.target.value.replace(/\D/g, "").substring(0, 3);
    setRadius(inputValue);
  };

  const getPlacePredictions = (input, inputType) => {
    return new Promise((resolve, reject) => {
      if (!autocompleteService.current) {
        autocompleteService.current =
          new window.google.maps.places.AutocompleteService();
      }

      autocompleteService.current.getPlacePredictions(
        {
          input,
          types: ["(regions)"],
          componentRestrictions: { country: "au" },
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve(predictions);
          } else {
            reject(status);
          }
        }
      );
    });
  };

  const selectSuburbOption = (place) => {
    // Check if geometry is present
    if (!place.geometry || !place.geometry.location) {
      console.error("Invalid place data:", place);
      return;
    }

    const addressComponents = place?.address_components;

    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );

    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;

      // Concatenate state if available
      if (stateComponent) {
        selectedSuburb += `, ${stateComponent.short_name}`;
      }
    }

    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }

    // Extract suburb and state separately
    let suburbAlone = suburbComponent?.long_name || "";
    let selectedState = stateComponent?.short_name || "";
    let postcode = selectedPostcode || "";

    // Check if location has latitude and longitude information
    const locationGeometry = place?.geometry;
    const latitude = locationGeometry?.location.lat() || null;
    const longitude = locationGeometry?.location.lng() || null;

    // Update your state variables
    setselectedSuburb(selectedSuburb);
    setselectedSuburbAlone(suburbAlone);
    setselectedPostcode(postcode);
    setselectedState(selectedState);
    setselectedLatitude(latitude);
    setselectedLongitude(longitude);
  };

  const handleKeyUp = async (e, inputType, item = "") => {
    const input = e.target.value || inputValue;
    console.log("Input value:", input);
    // Set the itemIdRef to the current item.id
    itemIdRef.current = item.id;

    if (e.key === "Enter") {
      e.preventDefault();
      try {
        const predictions = await getPlacePredictions(input, inputType);

        if (predictions && predictions.length > 0) {
          const firstPlaceId = predictions[0].place_id;
          const request = {
            placeId: firstPlaceId,
            fields: [
              "address_components",
              "formatted_address",
              "geometry",
              "place_id",
            ],
          };

          const placesService = new window.google.maps.places.PlacesService(
            document.createElement("div")
          );
          placesService.getDetails(request, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              if (inputType === "suburb") {
                selectSuburbOption(place);
              }
            } else {
              console.error("Error fetching place details:", status);
            }
          });
        }
      } catch (error) {
        console.error("Error fetching predictions:", error);
      }
    }
  };

  document.title = "Job Search | Get Rostered";

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer />
        <Card className="ribbon-box shadow-none">
          <CardBody>
            <Row className=" mt-0">
              <Col
                xl={4}
                className="d-flex gap-2 align-items-center mobie-view"
              >
                <div className="search-text-box flex-grow-1">
                  {isLoading ? (
                    <h5 className="placeholder-glow mt-1">
                      <span className="placeholder col-12"></span>
                    </h5>
                  ) : (
                    <div className="pb-0 position-relative">
                      <i
                        className="bx bxs-search-alt-2  position-absolute  icon-left"
                        style={{
                          color: "#EC1388",
                          top: "50%",
                          left: "15px",
                          transform: "translateY(-50%)",
                          fontSize: "1.5rem",
                        }}
                      ></i>
                      <input
                        type="text"
                        className="search-text-form-control"
                        id="searchJob"
                        value={searchQuery}
                        autoComplete="off"
                        placeholder="Enter Keywords"
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearch();
                          }
                        }}
                        style={{ paddingLeft: "45px" }}
                      />
                    </div>
                  )}
                </div>
              </Col>

              <Col
                xl={4}
                className="d-flex gap-2 align-items-center mobie-view"
              >
                <div className="search-text-box flex-grow-1">
                  {isLoading ? (
                    <h5 className="placeholder-glow mt-1">
                      <span className="placeholder col-12"></span>
                    </h5>
                  ) : (
                    <div className="pb-0 position-relative">
                      <i
                        className="bx bxs-map-pin position-absolute icon-left"
                        style={{
                          color: "#EC1388",
                          top: "50%",
                          left: "15px",
                          transform: "translateY(-50%)",
                          fontSize: "1.5rem",
                        }}
                      ></i>
                      <GoogleAutocomplete
                        className="search-text-form-control"
                        apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                        value={selectedSuburb}
                        id="searchLocation"
                        placeholder="Choose Location"
                        onKeyUp={(e) => handleKeyUp(e, "suburb")}
                        onPlaceSelected={selectSuburbOption}
                        onChange={(e) => {
                          setselectedSuburb(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearch();
                          }
                        }}
                        options={{
                          types: ["(regions)"],
                          componentRestrictions: {
                            country: "au",
                          },
                        }}
                        style={{
                          border: "none",
                          padding: "10px",
                          borderRadius: "20px",
                          paddingLeft: "45px",
                        }}
                      />
                    </div>
                  )}
                </div>
              </Col>

              <Col xl={4} className="d-flex gap-2 align-items-center ">
                <div className="search-text-box flex-grow-1">
                  {isLoading ? (
                    <h5 className="placeholder-glow mt-1">
                      <span className="placeholder col-12"></span>
                    </h5>
                  ) : (
                    <div className="pb-0 position-relative">
                      <i
                        className="bx bx-radar position-absolute icon-left"
                        style={{
                          color: "#EC1388",
                          top: "50%",
                          left: "15px",
                          transform: "translateY(-50%)",
                          fontSize: "1.5rem",
                        }}
                      ></i>
                      <input
                        type="number"
                        className="search-text-form-control"
                        id="searchRadius"
                        value={radius}
                        onChange={handleRadiusChange}
                        autoComplete="off"
                        placeholder="Enter Radius"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearch();
                          }
                        }}
                        style={{ paddingLeft: "45px" }}
                      />
                    </div>
                  )}
                </div>

                <button
                  className="search-text-btn "
                  onClick={handleSearch}
                  disabled={isLoading}
                >
                  <span>
                    <i className=" bx bx-search-alt text-primary search-text-icon"></i>
                  </span>
                </button>
                <button
                  className="search-text-btn "
                  onClick={handleReset}
                  disabled={isLoading}
                  style={{ backgroundColor: "rgb(244, 146, 199)" }}
                >
                  <span>
                    <i className="ri-refresh-line text-primary search-text-icon"></i>
                  </span>
                </button>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row>
          {isLoading
            ? // Render loading placeholders when isLoading is true
              [...Array(4)].map((_, index) => (
                <Col key={index} lg={3} className="mb-4 custom-column">
                  <Card className="profile_box_card" style={{ height: "100%" }}>
                    <CardBody className="p-1 text-center">
                      <div className="d-flex justify-content-center mt-4 mb-3">
                        <div className="placeholder-glow">
                          <span
                            className="placeholder col-12"
                            style={{
                              height: "5rem",
                              width: "5rem",
                              borderRadius: "50%",
                            }}
                          ></span>
                        </div>
                      </div>
                      <h5 className="placeholder-glow mt-3">
                        <span className="placeholder col-6"></span>
                      </h5>
                      <p className="placeholder-glow">
                        <span className="placeholder col-4"></span>
                      </p>
                      <p className="placeholder-glow">
                        <span className="placeholder col-8"></span>
                      </p>
                      <div className="skill_set_area mt-2 placeholder-glow">
                        <span className="placeholder col-6 me-2"></span>
                        <span className="placeholder col-6 me-2"></span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
            : // Render actual talent cards when isLoading is false
              jobs?.length > 0 &&
              jobs.map((job, index) => (
                <Col key={index} lg={3} className="mb-4 custom-column">
                  <Card className="profile_box_card" style={{ height: "100%" }}>
                    <NavLink
                      onClick={() => {
                        setOpenJobId(job.job_id);
                        toggleDefaultCanvas();
                      }}
                    >
                      <CardBody className="p-1 text-center">
                        <div className="d-flex justify-content-center mt-4 mb-3">
                          {/* {job.business_logo ? (
                    <img
                      src={job.business_logo}
                      alt=""
                      style={{ height: "5rem", width: "5rem" }}
                      className="avatar-xxs rounded-circle"
                    /> */}
                          {job.s3_url ? (
                            <img
                              src={job.s3_url}
                              alt=""
                              style={{ height: "5rem", width: "5rem" }}
                              className="avatar-xxs rounded-circle"
                            />
                          ) : (
                            <img
                              src={industry}
                              alt=""
                              style={{ height: "5rem", width: "5rem" }}
                              className="avatar-xxs rounded-circle"
                            />
                          )}
                        </div>

                        <h5 className="card-title mt-3">{job.job_title}</h5>

                        <p className="workforce_pricing_hour_text mb-0 mt-4">
                          {job.business_name}
                        </p>
                        <span className="fs-15 d-inline-flex align-items-center mt-3">
                          <i className="text-success bx bx-dollar-circle fs-14 me-2"></i>
                          {job.rate}
                          <span className="text-muted fs-12 ms-1"> /Hr</span>
                        </span>
                        <p className="location_set_area mb-0 mt-3">
                          <i
                            className="ri-map-pin-fill fs-14 me-2"
                            style={{
                              color: "#EC1388",
                            }}
                          ></i>
                          <span className="search-location-text">
                            &nbsp;{job.location ? job.location : "--"}
                          </span>
                        </p>
                        <div className="skill_set_area  mt-3">
                          {job?.job_skills && job?.job_skills.length > 0 ? (
                            job?.job_skills.map((skill, skillIndex) => (
                              <span
                                key={skillIndex}
                                className="badge badge-soft-skills fs-12 me-2 p-2"
                              >
                                {skill}
                              </span>
                            ))
                          ) : (
                            <Badge className="bg-soft-dark fs-12 me-2">
                              None
                            </Badge>
                          )}
                        </div>
                      </CardBody>
                    </NavLink>
                    <div className="add_to_talent_btn_area ms-4 me-4">
                      {console.log(
                        "is_applied:",
                        job?.is_applied,
                        "is_talent:",
                        job?.is_talent
                      )}
                      {job?.is_applied === true && job?.is_talent === 1 ? (
                        <button
                          className="btn btn-talent btn-sm mt-auto disabled"
                          disabled
                          color="success"
                          onClick={(e) => {
                            e.stopPropagation(); // Stops propagation
                          }}
                          style={{ backgroundColor: "#ffebfc" }}
                        >
                          <span className="icon-off">
                            <i className="ri-checkbox-circle-line align-top me-1"></i>
                            Already in Team
                          </span>
                        </button>
                      ) : job?.is_applied === true ? (
                        <button
                          className="btn btn-talent btn-sm mt-auto disabled"
                          disabled
                          color="success"
                          onClick={(e) => {
                            e.stopPropagation(); // Stops propagation
                          }}
                          style={{ backgroundColor: "#ffebfc" }}
                        >
                          <span className="icon-off">
                            <i className="ri-checkbox-circle-line align-top me-1"></i>
                            Application submitted
                          </span>
                        </button>
                      ) : job?.is_applied === false && job?.is_talent === 1 ? (
                        <button
                          className="btn btn-talent btn-sm mt-auto disabled"
                          disabled
                          color="success"
                          onClick={(e) => {
                            e.stopPropagation(); // Stops propagation
                          }}
                          style={{ backgroundColor: "#ffebfc" }}
                        >
                          <span className="icon-off">
                            <i className="ri-checkbox-circle-line align-top me-1"></i>
                            Already in Team
                          </span>
                        </button>
                      ) : (
                        <button
                          className="btn btn-talent btn-sm mt-auto"
                          color="success"
                          onClick={(e) => {
                            e.stopPropagation(); // Stops propagation
                            tog_ApplyNowModal(job.job_id);
                          }}
                        >
                          <span className="icon-off">
                            <i className="bx bxs-send align-middle align-center me-1"></i>
                            Apply Now
                          </span>
                        </button>
                      )}
                    </div>
                  </Card>
                </Col>
              ))}

          <Modal
            isOpen={open}
            toggle={toggleDefaultCanvas}
            style={{ minWidth: "50%", borderLeft: 0 }}
            centered
            scrollable={true}
            size="l"
            modalClassName="zoomIn"
            id="offcanvasExample"
          >
            <ModalBody
              className="grey-overall-bg p-0"
              style={{ overflowX: "hidden" }}
            >
              <JobView
                jobId={openJobId}
                applyJobModal={tog_applyJobDetail}
                toggle={toggleDefaultCanvas}
                reloadData={jobs}
              />
            </ModalBody>
          </Modal>
          <Modal
            size="l"
            isOpen={applyJobModal}
            toggle={() => {
              tog_applyJobDetail();
            }}
            centered
          >
            <ModalHeader className=" text-center m-auto">
              <h5 className="text-center fs-18">
                Are you sure you want to apply for this job?
              </h5>
              <p className=" fw-semibold fs-13 my-1">
                Your application will be submitted to the employer for review
              </p>
              <p className=" fw-semibold fs-13 my-1">Confirm to proceed</p>
            </ModalHeader>
            <ModalBody className="px-4 py-2">
              <label
                className=" text-start form-label fs-14"
                style={{ textAlign: "start" }}
              >
                Cover Letter
              </label>
              <textarea
                type="text"
                className="form-control"
                placeholder="Enter your application message here"
                value={message}
                rows={10}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
              <Row className="my-4 d-flex justify-content-center">
                <Col className=" hstack gap-3 p-0 justify-content-center">
                  <Button
                    className="btn btn-dark"
                    onClick={() => {
                      tog_applyJobDetail();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-success"
                    disabled={isApplying}
                    onClick={() => applyJob(message)}
                  >
                    {isApplying ? (
                      <>
                        <Spinner size="sm" color="light" className="me-2" />{" "}
                        Applying...
                      </>
                    ) : (
                      "Apply for the Job"
                    )}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Row>

        {!isLoading && (
          <>
            {searchQuery == null || lastSearchType === "" ? (
              <Card className="full-height-card mt-1">
                <CardBody className="d-flex flex-column align-items-center justify-content-center p-4 ">
                  <>
                    <Row className="w-100">
                      <Col
                        md={12}
                        className="d-flex justify-content-center align-items-center mb-3 imagesearch"
                      >
                        <img src={searchworkforceimage} alt="Logo" />
                      </Col>
                      <Label className="text-center custom-label ms-4">
                        "Start Looking For Your Jobs"
                      </Label>
                    </Row>
                    <Row className="w-100">
                      <Col
                        md={12}
                        className="d-flex justify-content-center align-items-center"
                      >
                      </Col>
                    </Row>
                  </>
                </CardBody>
              </Card>
            ) : (
              (jobs?.length == 0 || jobs?.count == 0) && (
                <Card className="full-height-card mt-1">
                  <CardBody className="d-flex flex-column align-items-center justify-content-center p-4 ">
                    <>
                      <Row className="w-100">
                        <Col
                          md={12}
                          className="d-flex justify-content-center align-items-center mb-3 imagesearch"
                        >
                          <img src={searchworkforceimage} alt="Logo" />
                        </Col>
                        <Label className="text-center custom-label ms-4">
                          "No Jobs found"
                        </Label>
                      </Row>
                      <Row className="w-100">
                        <Col
                          md={12}
                          className="d-flex justify-content-center align-items-center "
                        >
                          {/* <Label className="text-center custom-label ms-4">
                                  "No records found"
                                </Label> */}
                        </Col>
                      </Row>
                    </>
                  </CardBody>
                </Card>
              )
            )}
          </>
        )}

        {!isLoading && jobs?.length > 8 && (
          <Col lg={12}>
            <div className="text-center mb-3">
              {showLoader && (
                <div className="loader">
                  <button
                    className="btn btn-primary rounded-pill"
                    onClick={loadMore}
                  >
                    <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                    Load More
                  </button>
                </div>
              )}
              {!showLoader && (
                <button
                  className="btn btn-primary rounded-pill"
                  onClick={loadMore}
                >
                  <i className="mdi mdi-spin fs-20 align-middle me-2"></i>
                  Load More
                </button>
              )}
            </div>
          </Col>
        )}
      </div>
    </React.Fragment>
  );
};

export default JobSearch;
